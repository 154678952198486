<template>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="m-bot-15">
          <div class="row">
            <div class="col-sm-6 col-xs-12">
              <b-form-group>
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"                   
                    auto-complete="nope"
                  ></b-form-input>
                   <span class="search-icon" v-if="filter">
                     <i class="fas fa-times" @click="filter =''"></i>
                   </span>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>           
           
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
          <el-table :data="credit_list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
            <el-table-column fixed  label='Customer Name' prop="id" sortable width="200">
              <template slot-scope="scope">
             <a href="#" @click.prevent="viewCredit(scope.row.id)"><span v-if="scope.row.get_customer.first_name">{{scope.row.get_customer.first_name}}</span> <span v-if="scope.row.get_customer.last_name">{{scope.row.get_customer.last_name}}</span></a>
               
              </template>
            </el-table-column>
            <el-table-column label="Request Credit" prop="credit_limit" sortable width="150" align="center">
              <template slot-scope="scope">
              <span>${{scope.row.credit_limit?scope.row.credit_limit:0}}</span>
              </template>
            </el-table-column>
             <el-table-column label="Approve Credit" prop="approved_credit" sortable width="150" align="center">
              <template slot-scope="scope">
              <span>${{scope.row.approved_credit?scope.row.approved_credit:0}}</span>
              </template>
            </el-table-column>
            <el-table-column label="Available Credit" prop="available_credit" sortable width="150" align="center">
              <template slot-scope="scope">
              ${{scope.row.available_credit}}
              </template>
            </el-table-column>
            <el-table-column label="Balance" prop="balance_showing" sortable width="150" align="center">
              <template slot-scope="scope">
              ${{scope.row.balance_showing}} 
              </template>
            </el-table-column>                  
            <el-table-column label="Status" prop="status" sortable width="150" >
              <template slot-scope="scope">
              {{scope.row.status}} 
              </template>
            </el-table-column>           
             <el-table-column label="Last Paid" prop="last_paid" sortable width="150">
              
            </el-table-column>
             <el-table-column label="Created Date" prop="created_at" sortable width="150">
              <template slot-scope="scope">
              {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
              </template>
            </el-table-column>
            <el-table-column fixed="right" align="center" label="Actions" width="80">
              <template slot-scope="scope">
                <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                  <i class="flaticon2-menu-1"></i>
                  <el-dropdown-menu slot="dropdown" class="action-dropdown">                   
                    <el-dropdown-item :command="1" >View</el-dropdown-item>
                    <el-dropdown-item :command="2" >Delete</el-dropdown-item>                   
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
        <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[50, 100, 500, 1000]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>
        <v-dialog/>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getCreditList, deleteCredit} from "@/api/customer";
export default {
     name: 'credit',
       data() {
           return {
               credit_list:[],
               filter: null,
               pagination:{
                total_rows: 1,
                current_page: 1,
                last_page:null,
                per_page: 50,
                },
           }       
       },
       methods: {
        
      handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
      fetchData(p) {
        console.log(p);
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
        if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        
        getCreditList(params).then(response => {
          this.credit_list = response.data.data.data
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.credit_list, '_showDetails', true)
        })       
      },
      handleActions(evt, row){
       if(evt === 1){
          this.$router.push({path:'/credit/approve/'+row.id});
        }else if(evt === 2){
          this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
            title: 'Please Confirm',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
            if(value){
              this.loading = true;
              deleteCredit(row.id).then(response => {
                if(response.data.status){
                    this.fetchData(1)
                    this.$showResponse('success', response.data.message);
                }
                this.loading = false;
              })
            }else{
              return false;
            }
          })
        }
      },
       viewCredit(credit_id){
        this.$router.push({path:'/credit/approve/'+credit_id});
      },
           
       },
     mounted() {
           this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Dashboard", route: "/dashboard" },
             { title: "Credit", route: "/customer/credit" },
            
           ]);
     },
      watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
     created(){
         this.fetchData(1);
     }

}
</script>

